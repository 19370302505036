<template>
    <div>
        <b-row v-if="ad && !ad.approved" class="w-100 print-none">
            <b-col class="w-100">
                <b-alert variant="danger" class="p-1 text-center w-100" show><b>Ta mali oglas ni potrjen! Zato ga lahko vidite samo vi in administratorji. Na strani nebo viden!</b></b-alert>
            </b-col>
        </b-row>

        <blue-background class="print-none"/>
        <div v-if="ad">
            <b-row class="pt-1">
                <b-col cols="12" md="6">
                    <div class="h-100">
                        <b-img v-if="ad.main_photo != ''" class="main-photo" :src="`/api/user/v1/image/${ad.main_photo}`" fluid/>
                        <b-img v-else class="main-photo" :src="require('@/assets/images/esoseska/no-image.jpg')" fluid/>
                    </div>
                </b-col>
                <b-col cols="12" md="6" class="mt-2 mt-md-0 d-flex flex-column">
                    <h3 class="title">{{ ad.title }}</h3>
                    <router-link :to="{ name: 'ad-list', query: { category_id: ad.category_id }}">
                        {{category}}
                    </router-link>
                    <div class="mt-1 desc mb-2 h-100 d-flex flex-column">
                        <span class="c2c-description html-display" v-html="ad.content"/>
                        <div class="mt-auto d-flex align-items-center justify-content-between">
                            <a @click="goto('description')">> preberi več</a>
                            <div class="date">objavljen: {{dayjs(ad.added_date).format("DD.MM.YYYY")}}</div>
                        </div>
                    </div>
                    <div class="mt-auto mt-2">
                        <div class="p-1 d-flex justify-content-between" style="background: #F3F3F3; -webkit-print-color-adjust: exact;">
                            <div class="d-flex flex-column h-100">
                                <h2 style="font-weight: 400;font-size: 20px;line-height: 23px;">Cena:</h2>
                                <div class="mt-auto" v-if="ad.listing_type !== 4">
                                    <h1 v-if="ad.price_offer" class="mb-0 price">PO DOGOVORU</h1>
                                    <h1 v-else class="mb-0 price" style="line-height: 45px;">{{$convertPriceIntegerToFloat(ad.price)}} €</h1>
                                </div>
                            </div>
                           
                            <div class="d-flex align-items-end" v-if="new Date(ad.expires) <= Date.now()">
                                <div class="d-flex align-items-center">
                                    <img style="width: 20px; height: 20px; margin-right: 5px;" :src="require('@/assets/svg/Circle_Important.svg')"/>
                                    <div style="color: #FF2E2E; font-weight: bold; font-size: 17px; line-height: 30px;">
                                        NEAKTIVEN OGLAS
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="12" md="6" order="2" order-md="1" class="screen-photos">
                    <div v-if="photos && photos.length > 0">
                        <swiper :swiper-data="photos" :pagination="false" type="imageGallery"/>
                    </div>
                </b-col>

                <b-col  cols="12" md="6" order="1" order-md="2" v-if="new Date(ad.expires) <= Date.now()">
                    <div style="color: #FF2E2E; font-size: 17px;" v-if="ad.approved">
                        OGLAS JE POTEKEL
                    </div>
                    <div style="color: #FF2E2E; font-size: 17px;" v-else>
                        OGLAS ŠE NI POTRJEN, zato ne bo prikazan drugim uporabnikom
                    </div>
                </b-col>
            </b-row>
            <div v-if="ad" id="description" class="mt-2">
                <div class="c2c-description-all html-display" v-html="ad.content"/>
            </div>
            <div v-if="ad.additional_photos.length > 0" class="mt-2 mobile-photos">
                <div class="mx-1" v-for="(item, index) of ad.additional_photos" :key="'photo_'+ index">
                    <b-img v-if="item !== ad.main_photo" style="object-fit: cover;" fluid :src="'/api/user/v1/image/' + item"/>
                </div>
            </div>
            <div class="mt-2 p-1" style="background: #72A5D8; -webkit-print-color-adjust: exact;">
                <div class="text-white" style="font-style: normal;font-weight: 700;font-size: 20px;line-height: 18px;">Kontakti prodajalca</div>
            </div>
            <div class="py-2 px-2 px-md-4 contact-data text-center w-100" style="background: #F3F3F3; -webkit-print-color-adjust: exact;">
                <div v-if="user">Ime: <span style="font-weight: bold;">{{ user.name }} {{ user.surname }}</span></div>
                <div class="mt-2">Regija: <span style="font-weight: bold;">{{ ad.region || 'Ni podane regije' }}</span></div>
                <div>Kraj: <span style="font-weight: bold;">{{ ad.town || 'Ni podane lokacije' }}</span></div>
                <div v-if="user" class="mt-2">Telefon: <a style="font-weight: bold; color: black !important;" :href="'tel:' + user.telephone_number">{{ user.telephone_number || '/' }}</a></div>
                <div v-if="user">Email: <a style="font-weight: bold; color: black !important;"  :href="'mailto:' + user.email">{{ user.email || '/' }}</a></div>

                <div class="d-flex justify-content-center w-100 mt-2">
                    <b-button pill class="button-inactive d-flex align-items-center" :disabled="!ad.approved" v-if="new Date(ad.expires) <= Date.now()" @click="approveC2C">
                        <div>
                            AKTIVIRAJ OGLAS 
                        </div>
                        <img class="ml-1" style="width: 20px; height: 20px;" :src="require('@/assets/svg/Circle_Arrow.svg')"/>
                    </b-button>
                    <b-button pill class="badge-delete d-flex align-items-center ml-2" @click="deleteC2C"> 
                        <div>
                            IZBRIŠI 
                        </div>
                        <img class="ml-1" style="width: 20px; height: 20px;" :src="require('@/assets/svg/Circle_X.svg')"/>
                    </b-button>
                </div>

                <div class="mt-2" style="color: #FF2E2E; font-size: 17px;" v-if="new Date(ad.expires) <= Date.now() && ad.approved">
                    Po novi aktivaciji bo oglas aktiven do {{ new_expires_date }}
                </div>
                <div class="mt-2" style="color: #FF2E2E; font-size: 17px;" v-else-if="ad.approved">
                    Oglas je aktiven do {{ expires }}
                </div>
                <div class="mt-2" style="color: #FF2E2E; font-size: 17px;" v-else>
                    Oglas se bo aktiviral, ko bo potrjen.
                </div>
            </div>

            <div class="d-flex justify-content-center w-100 mt-4">
                <b-button pill class="d-flex align-items-center" @click="redirectToMyAds">
                    <div>
                        VSI MOJI OGLASI
                    </div>
                    <img class="ml-1" style="width: 20px; height: 20px;" :src="require('@/assets/svg/Circle_Arrow_Blue.svg')"/>
                </b-button>
            </div>
        </div>
        <ConfirmActionModal :modal-show="ModalShowConfirmAction" title="Potrdite izbris oglasa" v-on:confirm-action="deleteC2C" @hide="ModalShowConfirmAction=false" @close="ModalShowConfirmAction=false"/>
    </div>
</template>

<script>
    import Swiper from '../../Components/Swiper'
    import {BRow, BCol, BImg, BAlert, BButton} from 'bootstrap-vue'
    import BlueBackground from '@/views/Components/BlueBackground'
    import ConfirmActionModal from '@/views/Components/ConfirmActionModal.vue'
    export default {
        components: {
            BButton,
            BlueBackground,
            Swiper,
            BRow,
            BCol,
            BImg,
            BAlert,
            ConfirmActionModal
        },
        computed:{
            new_expires_date() {
                const date = new Date()
                date.setDate(date.getDate() - 30)

                const dayjs_date =  this.dayjs(date)

                if (dayjs_date) {
                    return dayjs_date.format('DD.MM.YYYY')
                }

                return null
            },
            date_posted() {
                const date = new Date(this.ad.date_posted)

                if (date) {
                    const dayjs_date =  this.dayjs(date)

                    if (dayjs_date) {
                        return dayjs_date.format('DD.MM.YYYY')
                    }
                }

                return null
            },
            expires() {
                const date = new Date(this.ad.expires)

                if (date) {
                    const dayjs_date =  this.dayjs(date)

                    if (dayjs_date) {
                        return dayjs_date.format('DD.MM.YYYY')
                    }
                }

                return null
            },
            price() {
                if (this.data && this.data.price) return `${ (this.data.price / 100).toFixed(2)  }€ `
                else return null
            }
        },
        data() {
            return {
                ad: null,
                classified_adID: null,
                photos: [],
                user: null,
                category: '',
                ModalShowConfirmAction: false

            }
        },
        methods:{
            async getCategory() {

                if (!this.ad) {
                    return
                }

                try {
                    const res = await this.$http.get(`/api/user/v1/c2c/category/${  this.ad.category_id}`)
                    if (res.data) this.category = res.data.title

                } catch (error) {
                    this.$printError('Prislo je do napake pri nalaganju oglasa!')
                }
            },
            showModal(evnt) {
                this.ModalShowConfirmAction = true
                evnt.stopPropagation()
            },
            async deleteC2C() {

                try {
                    await this.$http.delete(`/api/user/v1/c2c/user_c2c/${  this.ad.id}`)

                    this.$printSuccess('Oglas je bil uspešno izbrisan')
                    this.redirectToMyAds()

                } catch (error) {
                    this.$printError('Prislo je do napake pri nalaganju oglasa!')
                }
            },
            async approveC2C(evnt) {
                evnt.stopPropagation()
                try {
                    await this.$http.post(`/api/user/v1/c2c/user_c2c/${  this.ad.id}`)

                    this.ad.approved = true
                    this.ad.expires = new Date(new Date().getDate() + 30)
                    this.ad.approved_at = new Date()
                    this.ad.last_edit_time = new Date()

                    this.$printSuccess('Oglas je bil uspešno potrjen')
                    this.loadData()

                } catch (error) {
                    this.$printError('Prislo je do napake pri aktivaciji oglasa!')
                }

            },
            updateBreadcrumbs(title) {
                const breadCrumbIndex = this.$route.meta.breadcrumb.findIndex(x => x.isCustomBreadcrumb)

                if (breadCrumbIndex !== -1) {
                    this.$route.meta.breadcrumb[breadCrumbIndex].text = title

                    this.$root.$emit('force-update-breadcrumbs')
                } 
            },
            redirectToMyAds() {
                this.$scrollToTop()
                this.$router.push({ name: 'my-ads'})            
            },
            goto(id) {
                const element = document.getElementById(id)
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            },
            async loadData() {
                this.photos = []

                try {

                    const res = await this.$http.get(`/api/user/v1/c2c/user_c2c/${  this.classified_adID}`)
                    this.ad = res.data
                    this.updateBreadcrumbs(this.ad.title)
                    await this.getCategory()

                    for (const item of this.ad.additional_photos) {
                        this.photos.push({itemId: `${item}_idSlike`, src: `/api/user/v1/image/${  item}`, thumbnail: `/api/user/v1/image/thumbnail_${  item}`, thumbnailHeight: '100px'})
                    }

                    this.user = this.ad.author
                } catch (error) {
                    this.$printError('Prislo je do napake pri nalaganju oglasa!')
                    this.$scrollToTop()
                    await this.$router.push({ name: 'my-ads'})
                }

               
            }
        },
        mounted() {
            this.classified_adID = this.$route.params.ad_id
            this.loadData()
        },
        beforeRouteLeave(to, from, next) {
            // before leaving news page, reset breadcrumb title to empty
            this.updateBreadcrumbs('')
            next()
        }
    }
</script>

<style scoped>
.button-inactive{
    background: #1AE49B !important;
    border: none;
}
.button-inactive:disabled{
    background: #1ae49a86 !important;
    border: none;
}
a {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8 !important;
}
.price {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
}
.contact-data {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}
.title {
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
}
.main-photo {
  max-height: 500px !important;
}
.mobile-photos {
  display: none;
}
.c2c-description{
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.date {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #72A5D8;
}
@media only screen and (max-width: 500px) {
  .price{
    font-size: 25px !important;
  }
}
</style>
<style>
.c2c-description-all ul {
  padding-left: 20px !important;
}
</style>